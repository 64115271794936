<template>
  <div>
    <Permission/>
    <Breadcrumb/>
    <div class="title">Property</div>
    <div class="subtitle" v-html="fieldsData.name || '&nbsp;'"></div>
    <Tabs/>
    <section>
      <Form
        :fields="fieldsData"
        :canUpdate="canUpdate"
        @onSave="updateProperty"
        @newAddress="newAddress"
        @onAddressDeletion="onAddressDeletion"
      >
        <template v-slot:bottom v-if="isLoading">
          <b-button type="is-blue" disabled>Save</b-button>
        </template>
      </Form>
    </section>
    <Flash title="Congrats" :content="flashText" :show="showFlash" :hideFlash="hideFlash"></Flash>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import pageTitleMixin from '@utils/mixins/addy-plus-page-title-mixin.js'
import Permission from '@views/addy_plus/base/Permission.vue'
import Tabs from './Tabs.vue'
import Form from './new/Form.vue'
import Flash from '@components/modal/slide-down-flash.vue'
import { fetchPropertyDetails, updateProperty as updateProp } from '@api/addy-plus/properties'
import { camelToSnakeCase } from '@utils/common-methods/common'
import { defaultAddressObject } from '@utils/data/addy_plus_address_fields.js'
import Breadcrumb from '@components/breadcrumb/breadcrumb.vue'

export default {
  title: 'Property',
  mixins: [pageTitleMixin],
  components: {
    Permission,
    Tabs,
    Form,
    Flash,
    Breadcrumb,
  },
  data() {
    return {
      fieldsData: {},
      isLoading: false,
      showFlash: false,
      timer: null,
      flashText: '',
    }
  },
  computed: {
    ...mapState('addyPlusBase', ['permissions']),
    propertyId() {
      return this.$route.params.propertyId
    },
    corporationId() {
      return this.$route.params.corporationId
    },
    canUpdate() {
      return this.permissions.asset?.includes('update')
    },
  },
  mounted() {
    this.init()
  },
  watch: {
    propertyId() {
      this.init()
    },
  },
  methods: {
    init() {
      fetchPropertyDetails(this.propertyId).then((res) => {
        if (!res.success) return
        const data = res.data
        // for (const key in data.issuanceMeta) {
        //   if (Object.hasOwnProperty.call(data.issuanceMeta, key)) {
        //     data[key] = data.issuanceMeta[key]
        //   }
        // }
        // Currently don't show or update any issuance meta for addyPlus
        delete data.issuanceMeta
        this.fieldsData = data
      })
    },
    updateProperty(params) {
      this.isLoading = true
      const payload = { ...this.parseParams(params), corporation_id: this.corporationId }
      updateProp(this.propertyId, payload).then((res) => {
        if (!res.success) return
        this.flashText = 'Property updated!'
        this.showUpdateFlash()
      }).finally(() => {
        this.isLoading = false
      })
    },
    parseParams(payload) {
      const obj = {}
      for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
          obj[camelToSnakeCase(key)] = key === 'addresses' ? this.parseAddresses(payload[key]) : payload[key]
        }
      }
      return obj
    },
    parseAddresses(payload) {
      return payload.map((address) => this.parseParams(address))
    },
    showUpdateFlash() {
      this.showFlash = true
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.hideFlash()
      }, 4000)
    },
    hideFlash() {
      this.showFlash = false
    },
    newAddress() {
      if (!this.fieldsData.addresses) {
        this.$set(this.fieldsData, 'addresses', [])
      }
      const newAddressObject = JSON.parse(JSON.stringify(defaultAddressObject))
      this.fieldsData.addresses.push(newAddressObject)
    },
    onAddressDeletion(index) {
      const addresses = this.fieldsData.addresses
      if (addresses[index].id) { // if id exists, then address object from fetch
        // remove from db
        const payload = {
          corporation_id: this.corporationId,
          addresses: [{ id: addresses[index].id, _destroy: true }]
        }
        updateProp(this.propertyId, payload).then((res) => {
          if (!res.success) return
          this.flashText = 'Address deleted!'
          this.showUpdateFlash()
          addresses.splice(index, 1)
        }).finally(() => {
          this.isLoading = false
        })
      } else {
        // remove from frontend
        addresses.splice(index, 1)
      }
    },
  },
}
</script>
